import { Stack } from '@mui/material';
import * as React from 'react';

import { Header, Feed } from '../components';

export default function App() {
  return (
    <Stack component="main" minHeight="100vh">
      <Header />
      <Feed />
    </Stack>
  );
}
