import { Box, InputBase, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';

export default function SearchField() {
  return (
    <Stack
      direction="row"
      sx={{
        height: 40,
        borderRadius: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.25)',
        },
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" ml={1}>
        <SearchIcon />
      </Box>
      <InputBase placeholder="Search..." sx={{ px: 1, color: 'inherit' }} />
    </Stack>
  );
}
