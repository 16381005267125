import { BackdropProvider } from './backdrop';
import { ColorModeProvider } from './colorMode';
import { SnackbarProvider } from './snackbar';

export default function AppProviders({ children }) {
  return (
    <ColorModeProvider>
      <BackdropProvider>
        <SnackbarProvider>{children}</SnackbarProvider>
      </BackdropProvider>
    </ColorModeProvider>
  );
}
